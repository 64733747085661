/**
 * 日期相关操作封装
 */
/* 补零 */
export function padZero(n) {
    return n < 10 ? '0' + n : n;
}

/* 获取年月日 */
export function getYMD(separator = '/', t = Date.now()) {
    const date = new Date(typeof separator == 'number' ? separator : t)

    let y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()

    // return y + s + padZero(m) + s + padZero(d)
    return [y, m, d].map(function (v) {
        return padZero(v)  // [2021, 07, 08]
    }).join(typeof separator == 'number' ? '/' : separator)
}

/* 获取时分秒 */
export function getHMS(separator = ':', t = Date.now()) {
    const date = new Date(typeof separator == 'number' ? separator : t)
    let h = date.getHours()
    let m = date.getMinutes()
    let s = date.getSeconds()

    return [h, m, s].map(function (v) {
        return padZero(v)  // [2021, 07, 08]
    }).join(typeof separator == 'number' ? ':' : separator)
}

/* 获取年月日 时分秒 */
export function getYMDHMS(s1, s2, t) {
    return getYMD(s1, t) + ' ' + getHMS(s2, t)
}

/* 获取星期 */
export function getWeek(t = Date.now()) {
    return '星期' + ['天', '一', '二', '三', '四', '五', '六'][new Date(t).getDay()]
}



