<template>
  <div class="goods-list">
    <el-card class="box-card">
      <!-- 标题 -->
      <div slot="header" class="clearfix">
        <span>心愿列表</span>
      </div>

      <!-- 内容 -->
      <div class="content" :style="{ width: w + 'px' }">
        <!-- 查询表单 -->
        <el-form :model="searchForm" label-width="80px" :inline="true">
          <!-- 商品名称 -->
          <el-form-item label="商品名称">
            <el-input
              size="small"
              v-model="searchForm.goods_name"
              clearable
              @change="getData"
              placeholder="请输入商品名称"
            ></el-input>
          </el-form-item>

          <!-- 心愿状态 -->
          <el-form-item label="心愿状态">
            <el-select
              size="small"
              v-model="searchForm.state"
              placeholder="请选择状态"
              @change="getData"
            >
              <el-option label="未查看" :value="-1"></el-option>
              <el-option label="已查看" :value="1"></el-option>
              <el-option label="全部" :value="0"></el-option>
            </el-select>
          </el-form-item>

          <!-- 商品分类 -->
          <el-form-item label="商品分类">
            <el-select
              size="small"
              v-model="searchForm.type_id"
              placeholder="请选择分类"
              @change="getData"
            >
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- 按钮 -->
          <el-form-item style="margin-left: 20px">
            <el-button size="small" type="primary" @click="search"
              >查询</el-button
            >
            <el-button size="small" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>

        <div class="download">
          <el-date-picker
            v-model="time"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            size="small"
            @change="getTime"
          >
          </el-date-picker>

          <el-button
            type="success"
            size="small"
            @click="download"
            icon="el-icon-download"
            :disabled="isDownload"
          >
            导出列表
          </el-button>
        </div>

        <!-- 商品列表 -->
        <el-table :data="tableData" style="width: 100%">
          <el-table-column label="序号" type="index" width="50">
          </el-table-column>

          <el-table-column label="用户信息" min-width="200px">
            <template slot-scope="scope">
              <div class="name">
                <el-image
                  style="width: 54px; height: 54px"
                  :src="scope.row.user_info.avatar_url"
                  :preview-src-list="[scope.row.user_info.avatar_url]"
                >
                </el-image>
                <div class="name-text">
                  <div>
                    {{ scope.row.user_info.nick_name }}
                  </div>
                  <div
                    :title="scope.row.phone"
                    v-if="scope.row.user_info.phone"
                  >
                    {{ scope.row.user_info.phone }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="所在粉丝群" min-width="150px" prop="group">
          </el-table-column>

          <el-table-column label="商品名称" min-width="150px" prop="goods_name">
          </el-table-column>

          <el-table-column label="品牌名称" min-width="150px" prop="brand_name">
          </el-table-column>

          <el-table-column label="分类" min-width="200px">
            <template slot-scope="scope">
              <div v-if="scope.row.one_cate_info">
                {{ scope.row.one_cate_info.name }}
              </div>
            </template>
          </el-table-column>

          <el-table-column label="提交时间" min-width="200px" prop="created_at">
          </el-table-column>

          <!-- 操作 -->
          <el-table-column
            label="操作"
            width="200px"
            fixed="right"
            v-if="searchForm.state === -1"
          >
            <template slot-scope="scope">
              <el-button
                type="primary"
                plain
                @click="operate(scope.row.id)"
                size="small"
              >
                标记为已查看
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页 -->
        <el-pagination
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 15, 20, 25, 30, 35]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
// 引入ajax函数
import { getWishList, operateWish, download } from "@/api/wishlist";
// import { goodsTypes } from "@/api/goods";
// 引入日期工具函数
import { getYMDHMS } from "@/utils/date";

import axios from "axios";

export default {
  // 数据
  data() {
    return {
      isDownload: true, // 下载按钮是否可点击
      time: "", // 下载时间
      // 时间选择器快捷选项
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      isClick: false, // 是否点击下载按钮
      options: [], // 分类选项
      title: "", // 规格模态框的标题
      dialogVisible: false, // 规格对话框
      tableData: [], // 商品列表数据
      currentPage: 1, //当前页
      pageSize: 5, // 每页显示数量
      total: 0, // 总条数
      checkId: "", // 查看规格的id
      delID: "", // 删除商品的id
      delShow: false, // 删除商品的模态框

      // 规格列表
      specsList: [],

      // 查询表单
      searchForm: {
        goods_name: "",
        state: -1,
        type_id: "",
      },

      // 宽度适配
      w:
        document.body.clientWidth > 1000
          ? document.body.clientWidth - 300
          : document.body.clientWidth - 165,
    };
  },

  // 方法
  methods: {
    //   获取时间
    getTime(val) {
      if (val) {
        this.isDownload = false;
      } else {
        this.isDownload = true;
      }
    },
    // 导出列表
    async download() {
      if (!this.isClick) {
        this.isClick = true;
        let params = {
          start_time: this.time[0] + " 00:00:00",
          end_time: this.time[1] + " 23:59:59",
        };
        let url = window.location.href.split("#")[0];
        if (url.includes("localhost")) {
          url = url + "api/admin/exports";
        } else {
          url = url + "admin/exports";
        }

        axios
          .request({
            url, //请求路径
            responseType: "blob",
            method: "get",
            params,
          })
          .then((res) => {
            //此处有个坑。这里用content保存文件流，最初是content=res，但下载的test.xls里的内容如下图1，
            //检查了下才发现，后端对文件流做了一层封装，所以将content指向res.data即可
            //另外，流的转储属于浅拷贝，所以此处的content转储仅仅是便于理解，并没有实际作用=_=
            const content = res.data;
            const blob = new Blob([content]); //构造一个blob对象来处理数据
            const fileName = "心愿列表.xlsx";

            //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
            //IE10以上支持blob但是依然不支持download
            if ("download" in document.createElement("a")) {
              //支持a标签download的浏览器
              const link = document.createElement("a"); //创建a标签
              link.download = fileName; //a标签添加属性
              link.style.display = "none";
              link.href = URL.createObjectURL(blob);
              document.body.appendChild(link);
              link.click(); //执行下载
              URL.revokeObjectURL(link.href); //释放url
              document.body.removeChild(link); //释放标签
            } else {
              //其他浏览器
              navigator.msSaveBlob(blob, fileName);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$message.warning("请勿重复点击！");
      }

      // console.log(res);
    },
    // 处理心愿单
    async operate(id) {
      let res = await operateWish({ id });
      if (res.code === 200) {
        this.$message({
          message: "标记成功!",
          type: "success",
        });
        this.getData();
      }
    },

    // 当前显示页数改变时
    sizeChange(val) {
      this.pageSize = val;
      this.getData();
    },

    // 当前页改变时
    currentChange(val) {
      this.currentPage = val;
      this.getData();
    },

    // 查询
    search() {
      this.getData();
    },

    // 重置查询
    reset() {
      this.searchForm = {
        goods_name: "",
        state: -1,
        type_id: "",
      };

      this.getData();
    },

    // 获取分类
    async getTypes() {
      let res = await goodsTypes({ p_id: 0 });
      if (res.code === 200) {
        this.options = res.data;
      }
    },

    // 获取数据
    async getData() {
      let data = {
        goods_name: this.searchForm.goods_name,
        show: this.searchForm.state,
        page: this.currentPage,
        size: this.pageSize,
        type_id: this.searchForm.type_id,
      };
      let res = await getWishList(data);
      let { list, totalSize } = res.data;

      // 解决删除小bug ( 如果当前页没有数据 且 当前页不是第一页 )
      if (!list.length && this.currentPage !== 1) {
        this.currentPage -= 1; // 页码减去1
        this.getData(); // 再重新请求数据 渲染
      }

      list.forEach((v) => {
        v.created_at = getYMDHMS("-", ":", v.created_at * 1000);
      });

      this.total = totalSize;
      this.tableData = list;
    },

    // 适配宽度
    fitWidth() {
      window.addEventListener("resize", () => {
        this.w =
          document.body.clientWidth > 1000
            ? document.body.clientWidth - 300
            : document.body.clientWidth - 165;
      });
    },
  },

  // 观察数据
  watch: {
    isClick() {
      if (this.isClick) {
        let timeID = setTimeout(() => {
          this.isClick = false;
          clearTimeout(timeID);
        }, 5000);
      }
    },
  },

  // 生命周期-实例创建完成后
  created() {
    // 获取列表数据
    this.getData();
    this.getTypes();

    // 适配宽度
    this.fitWidth();
  },
};
</script>

<style lang="less" scoped>
// 商品名称
.name {
  display: flex;
  align-items: center;
  .el-image {
    margin-right: 10px;
    border-radius: 4px;
  }
}

// 主要内容
.content {
  // 添加按钮
  .add-btn {
    margin-bottom: 20px;
  }

  //   驳回原因
  .fail-detail {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // 查看规格
  .check {
    color: #3396fa;
    cursor: pointer;
  }
}

// 商品详情表单
.el-table__cell {
  .el-form {
    padding-left: 50px;
    .el-form-item {
      margin-right: 0;
      margin-bottom: 0;
      margin-bottom: 15px;
      width: 50%;
    }
  }
}

// 抽屉
.drawer__content {
  .el-form {
    .el-form-item {
      width: 100%;
      .el-input {
        width: 210px;
      }
      /deep/.el-textarea__inner {
        width: 90%;
      }
    }
  }

  .drawer__footer {
    padding-left: 50px;
    padding-top: 20px;
  }
}

// 商品图片上传
/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}

// 操作中的修改按钮
.edit-btn {
  margin-right: 10px;
}

// 分页组件
.el-pagination {
  margin-top: 20px;
}

.download {
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 20px;

  .el-button {
    margin-left: 20px;
  }

  /deep/.el-date-editor .el-range-separator {
    width: 6% !important;
  }
}
</style>
