
/**
 * 订单模块的ajax函数
 */

// 引入设置好的axios
import request from '@/utils/request'

// post请求
// 修改供应商信息
// export const merchantEdit = (data) => {
//     return request({
//         url: 'api/wares/merchantEdit',
//         method: "post",
//         data
//     })
// }

// 订单列表 
export const getWishList = (params) => {
    return request({
        url: '/admin/desires',
        method: "get",
        params
    })
}

// 愿望处理
export const operateWish = (data) => {
    return request({
        url: '/admin/handleDesires',
        method: "post",
        data
    })
}
// 导出愿望数据
export const download = (params) => {
    return request({
        url: '/admin/exports',
        method: "get",
        params,
        responseType: 'blob',
    })
}
